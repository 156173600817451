$darkPrimaryColor: #169697;

.markup-option {
  cursor: url("../assets/grab-green.png") 9 9, grab !important;

  &.dragging {
    cursor: url("../assets/grabbing-green.png") 9 9, grabbing !important;
  }
}

.edit-tapestry {
  .handle {
    display: none;

    &::before {
      content: "";
      position: absolute;
      inset: -6px;
      background: transparent;
    }
  }

  &.editing-phrases {
    .svg-container {
      cursor: url("../assets/pointer-black-shadow.png") 12 9, pointer;
    }

    text.editable,
    .clickable-text,
    .editable-textpath {
      cursor: url("../assets/pointer-green-shadow.png") 12 9, pointer !important;
    }

    .tap-markup {
      cursor: url("../assets/grab-green.png") 9 9, grab !important;

      &.dragging {
        cursor: url("../assets/grabbing-green.png") 9 9, grabbing !important;
      }

      &:hover {
        img {
          outline: 1px solid $darkPrimaryColor;
        }

        .handle {
          display: block;
        }
      }
    }
  }

  &.searching-phrases {
    .svg-container {
      cursor: url("../assets/search-black-shadow.png") 20 18, pointer;
    }

    text.editable,
    .clickable-text,
    .editable-textpath {
      cursor: url("../assets/search-green-shadow.png") 20 18, pointer !important;
    }

    .symbol-phrase-container:hover textPath {
      fill: $darkPrimaryColor;
    }
  }
}
